import "./App.css";
import Loading from "./components/Loading";
import DonateButton from "./components/DonateButton/DonateButton";
import Navbar from "./components/Navbar/Navbar";
import Header from "./components/Header/Header";
import About from "./components/About/About";
import News from "./components/News/News";
import Campaigns from "./components/Campaigns/Campaigns";
import Projects from "./components/Projects/Projects";
import Donate from "./components/Donate/Donate";
import Contact from "./components/Contact/Contact";
import ScrollToTop from "react-scroll-to-top";
import { Element } from "react-scroll";
import { Suspense, useState } from "react";
import LocaleContext from "./LocaleContext";
import i18n from "./i18n";

function App() {
    const [locale, setLocale] = useState(i18n.language);
    const [bool, setBool] = useState(false);
    i18n.on("languageChanged", (lng) => {
        setLocale(i18n.language);
        setBool(!bool);
    });

    return (
        <LocaleContext.Provider value={{ locale, setLocale }}>
            <Suspense fallback={<Loading />}>
                <DonateButton />
                <Navbar />
                <Element id="headerId" name="header">
                    <Header />
                </Element>
                <Element id="aboutId" name="about">
                    <About />
                </Element>
                <Element id="newsId" name="news">
                    <News key={bool} />
                </Element>
                <Element id="campaignsId" name="campaigns">
                    <Campaigns key={bool} />
                </Element>
                <Element id="projectsId" name="projects">
                    <Projects key={bool} />
                </Element>
                <Element id="donateId" name="donate">
                    <Donate />
                </Element>
                <Element id="contactId" name="contact">
                    <Contact />
                </Element>
                <ScrollToTop smooth="true" />
            </Suspense>
        </LocaleContext.Provider>
    );
}

export default App;
