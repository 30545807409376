import React, { useState, useEffect } from "react";
import "./Campaigns.css";
import useCampaigns from "./useCampaigns";
import { useTranslation } from "react-i18next";

const Campaigns = ({ useless }) => {
    const { t } = useTranslation();
    const [numberOfElementsToDisplay, setNumberOfElementsToDisplay] = useState(getInitialAmount());
    const campaigns = useCampaigns();

    function getInitialAmount() {
        if (window.innerWidth <= 932) {
            return 3;
        } else if (window.innerWidth >= 1366) {
            return 3;
        } else {
            return 4;
        }
    }

    useEffect(() => {
        function handleResize() {
            setNumberOfElementsToDisplay(getInitialAmount());
        }

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // const handleShowMoreClick = () => {
    //     setNumberOfElementsToDisplay(campaigns.length);
    // };

    return (
        <div className="campaigns-container">
            <div className="title-container">
                <p>{t("cpgn-title")}</p>
            </div>

            <div className="campaigns-content">
                <ul className="campaigns-ul">
                    {campaigns.slice(0, numberOfElementsToDisplay).map((campaign) => (
                        <li key={campaign.key}>{campaign.content}</li>
                    ))}
                </ul>
            </div>

            {/* <div className="show-more-btn" onClick={handleShowMoreClick}>
                <p>{t("cpgn-show-more-btn")}</p>
            </div> */}
        </div>
    );
};

export default Campaigns;
