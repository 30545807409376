import React from "react";
import "./Contact.css";
import logo from "../../h4k_logo.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faTiktok, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faCopyright } from "@fortawesome/free-regular-svg-icons";
import cr_ds from "../../data_protection/cr_ds.pdf";
import { useTranslation } from "react-i18next";

const Contact = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="contact-container">
                <div className="title-container">
                    <p>{t("cont-title")}</p>
                </div>
                <div className="contact-logo">
                    <img src={logo} alt="logo" />
                </div>
                <div className="contact-socials">
                    <a
                        href="https://www.instagram.com/hopeforkids__?igsh=MWY0Y2s3YTBmYjBvZQ%3D%3D&utm_source=qr"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FontAwesomeIcon className="icon" id="a" icon={faInstagram} />
                    </a>
                    <a
                        href="https://www.tiktok.com/@hopeforkids__?_t=8jbgMqhc8Uz&_r=1"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FontAwesomeIcon className="icon" id="b" icon={faTiktok} />
                    </a>
                    <a
                        href="https://www.facebook.com/profile.php?id=100082016421817"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FontAwesomeIcon className="icon" id="c" icon={faFacebook} />
                    </a>
                    <a href="mailto:info@hopeforkids.ch" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon className="icon" id="e" icon={faEnvelope} />
                    </a>
                </div>
                <div className="contact-info">
                    <p id="a">{t("cont-info-title")}</p>
                    <p id="b">{t("cont-info-bank")}: CH47 0900 0000 1578 0481 7</p>
                    <p id="b">{t("cont-info-postbank")}: POFICHBEXXX</p>
                    <p id="b">PayPal: hopeforkids@gmx.net</p>
                </div>

                <div className="location">
                    <p id="gm-subtitle">{t("cont-google-maps-title")}</p>
                    <iframe
                        title="Google Maps location"
                        className="google-location"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d21596.798439727394!2d8.349879841549111!3d47.419746575106316!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47900d7fdfb6b3eb%3A0x3d45724e100b1521!2sSpreitenbach!5e0!3m2!1sde!2sch!4v1697469035054!5m2!1sde!2sch"
                        allowFullScreen=""
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>

                <div className="impressum">
                    <p id="a">{t("cont-impressum")}</p>
                    <p id="b">{t("cont-org")}</p>
                    <p id="b">Hope for Kids</p>
                    <p id="b">Fluestrasse 8</p>
                    <p id="b">8957 Spreitenbach</p>
                    <p id="b">{t("cont-country")}</p>

                    <p id="c">
                        {t("cont-email")}: <a href="mailto:info@hopeforkids.ch">info@hopeforkids.ch</a>
                    </p>
                    <p id="c">{t("cont-number")}: +41 77 227 02 00</p>

                    <p id="dev">
                        {t("cont-dev")}
                        <a href="https://linktr.ee/ahmedmalanovic" target="_blank" rel="noopener noreferrer">
                            Ahmed Malanovic
                        </a>
                    </p>
                </div>
            </div>

            <div className="hr-line"></div>
            <div className="footer">
                <div className="copyright">
                    <p>
                        <FontAwesomeIcon className="icon" icon={faCopyright} />
                        2024 Hope For Kids
                    </p>
                </div>
                <div className="data-protection">
                    <a href={cr_ds} target="_blank" rel="noopener noreferrer">
                        <p>{t("cont-data-protection")}</p>
                    </a>
                </div>
            </div>
        </>
    );
};

export default Contact;
