import React, { useState, useEffect } from "react";
import kb1 from "../../images/kb1.jpeg";
import kb2 from "../../images/kb2.jpeg";
import kb3 from "../../images/kb3.jpeg";
import kb4 from "../../images/kb4.jpeg";
import kb5 from "../../images/kb5.jpeg";
import kb6 from "../../images/kb6.jpeg";
import kb7 from "../../images/kb7.jpeg";
import kb8 from "../../images/kb8.jpeg";
import sz1 from "../../images/sz1.jpeg";
import sz2 from "../../images/sz2.jpeg";
import rd1 from "../../images/rd1.jpeg";
import rd2 from "../../images/rd2.jpeg";
import rd3 from "../../images/rd3.jpeg";
import Scroll from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faInfo, faXmark } from "@fortawesome/free-solid-svg-icons";
import Popup from "reactjs-popup";
import { Slide } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";

const useCampaigns = () => {
    const { t } = useTranslation();
    const AutoplaySlider = withAutoplay(AwesomeSlider);
    const [offset, setOffset] = useState(-110);

    useEffect(() => {
        const mediaQueries = [
            { query: "(min-width: 1366px)", offset: -110 },
            { query: "(min-width: 933px) and (min-height: 431px)", offset: -110 },
            { query: "(max-width: 932px) and (max-height: 430px)", offset: -80 },
        ];

        const handleMediaQueryChange = () => {
            const matchingMediaQuery = mediaQueries.find((mq) => window.matchMedia(mq.query).matches);

            if (matchingMediaQuery) {
                setOffset(matchingMediaQuery.offset);
            } else {
                setOffset(-110);
            }
        };

        mediaQueries.forEach((mq) => {
            const mediaQuery = window.matchMedia(mq.query);
            mediaQuery.addEventListener("change", handleMediaQueryChange);
        });

        handleMediaQueryChange();

        return () => {
            mediaQueries.forEach((mq) => {
                const mediaQuery = window.matchMedia(mq.query);
                mediaQuery.removeEventListener("change", handleMediaQueryChange);
            });
        };
    }, []);

    const [campaigns] = useState([
        {
            key: "campaigns-0",
            content: (
                <div className="slide-wrapper">
                    <Slide direction={"up"} triggerOnce={true} fraction={0.33}>
                        <div className="campaign">
                            <div className="cmp-desc-container">
                                <p id="description">{t("cpgn-name-0")}</p>
                            </div>
                            <AutoplaySlider
                                className="autoplay-slider-container aws-btn"
                                play={true}
                                cancelOnInteraction={false}
                                interval={3000}
                                infinite={false}
                            >
                                <div className="cmp-img-container">
                                    <img src={kb1} alt="1" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={kb2} alt="2" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={kb3} alt="3" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={kb4} alt="4" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={kb5} alt="5" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={kb6} alt="6" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={kb7} alt="7" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={kb8} alt="8" />
                                </div>
                            </AutoplaySlider>

                            <div className="cta-buttons">
                                <Popup
                                    trigger={
                                        <div className="btn1-container">
                                            <p id="btn1">
                                                <FontAwesomeIcon className="icon" icon={faInfo} />
                                                {t("cpgn-more-info-btn")}
                                            </p>
                                        </div>
                                    }
                                    modal
                                    lockScroll={true}
                                >
                                    {(close) => (
                                        <div className="modal">
                                            <FontAwesomeIcon className="icon" onClick={close} icon={faXmark} />
                                            <div className="title">
                                                <p>{t("cpgn-name-0")}</p>
                                            </div>
                                            <div className="campaign-info">
                                                <p>{t("cpgn-desc-00")}</p>
                                                <p>{t("cpgn-desc-01")}</p>
                                                <p>{t("cpgn-desc-02")}</p>
                                                <p>{t("cpgn-desc-03")}</p>
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                                <Scroll.Link to="donateId" spy={true} duration={250} smooth={false} offset={offset}>
                                    <div className="btn2-container">
                                        <p id="btn2">
                                            <FontAwesomeIcon className="icon" icon={faHeart} />
                                            {t("cpgn-donate-btn")}
                                        </p>
                                    </div>
                                </Scroll.Link>
                            </div>
                        </div>
                    </Slide>
                </div>
            ),
        },
        {
            key: "campaigns-1",
            content: (
                <div className="slide-wrapper">
                    <Slide direction={"up"} triggerOnce={true} fraction={0.33}>
                        <div className="campaign">
                            <div className="cmp-desc-container">
                                <p id="description">{t("cpgn-name-1")}</p>
                            </div>
                            <AutoplaySlider
                                className="autoplay-slider-container aws-btn"
                                play={true}
                                cancelOnInteraction={false}
                                interval={3000}
                                infinite={false}
                            >
                                <div className="cmp-img-container">
                                    <img src={sz1} alt="1" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={sz2} alt="2" />
                                </div>
                            </AutoplaySlider>

                            <div className="cta-buttons">
                                <Popup
                                    trigger={
                                        <div className="btn1-container">
                                            <p id="btn1">
                                                <FontAwesomeIcon className="icon" icon={faInfo} />
                                                {t("cpgn-more-info-btn")}
                                            </p>
                                        </div>
                                    }
                                    modal
                                    lockScroll={true}
                                >
                                    {(close) => (
                                        <div className="modal">
                                            <FontAwesomeIcon className="icon" onClick={close} icon={faXmark} />
                                            <div className="title">
                                                <p>{t("cpgn-name-1")}</p>
                                            </div>
                                            <div className="campaign-info">
                                                <p>{t("cpgn-desc-10")}</p>
                                                <p>{t("cpgn-desc-11")}</p>
                                                <p>{t("cpgn-desc-12")}</p>
                                                <p>{t("cpgn-desc-13")}</p>
                                                <p>{t("cpgn-desc-14")}</p>
                                                <p>{t("cpgn-desc-15")}</p>
                                                <p>{t("cpgn-desc-16")}</p>
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                                <Scroll.Link to="donateId" spy={true} duration={250} smooth={false} offset={offset}>
                                    <div className="btn2-container">
                                        <p id="btn2">
                                            <FontAwesomeIcon className="icon" icon={faHeart} />
                                            {t("cpgn-donate-btn")}
                                        </p>
                                    </div>
                                </Scroll.Link>
                            </div>
                        </div>
                    </Slide>
                </div>
            ),
        },
        {
            key: "campaigns-2",
            content: (
                <div className="slide-wrapper">
                    <Slide direction={"up"} triggerOnce={true} fraction={0.33}>
                        <div className="campaign">
                            <div className="cmp-desc-container">
                                <p id="description">{t("cpgn-name-2")}</p>
                            </div>
                            <AutoplaySlider
                                className="autoplay-slider-container aws-btn"
                                play={true}
                                cancelOnInteraction={false}
                                interval={3000}
                                infinite={false}
                            >
                                <div className="cmp-img-container">
                                    <img src={rd1} alt="1" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={rd2} alt="2" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={rd3} alt="3" />
                                </div>
                            </AutoplaySlider>

                            <div className="cta-buttons">
                                <Popup
                                    trigger={
                                        <div className="btn1-container">
                                            <p id="btn1">
                                                <FontAwesomeIcon className="icon" icon={faInfo} />
                                                {t("cpgn-more-info-btn")}
                                            </p>
                                        </div>
                                    }
                                    modal
                                    lockScroll={true}
                                >
                                    {(close) => (
                                        <div className="modal">
                                            <FontAwesomeIcon className="icon" onClick={close} icon={faXmark} />
                                            <div className="title">
                                                <p>{t("cpgn-name-2")}</p>
                                            </div>
                                            <div className="campaign-info">
                                                <p>{t("cpgn-desc-20")}</p>
                                                <p>{t("cpgn-desc-21")}</p>
                                                <p>{t("cpgn-desc-22")}</p>
                                                <p>{t("cpgn-desc-23")}</p>
                                                <p>{t("cpgn-desc-24")}</p>
                                                <p>{t("cpgn-desc-25")}</p>
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                                <Scroll.Link to="donateId" spy={true} duration={250} smooth={false} offset={offset}>
                                    <div className="btn2-container">
                                        <p id="btn2">
                                            <FontAwesomeIcon className="icon" icon={faHeart} />
                                            {t("cpgn-donate-btn")}
                                        </p>
                                    </div>
                                </Scroll.Link>
                            </div>
                        </div>
                    </Slide>
                </div>
            ),
        },
    ]);

    return campaigns;
};

export default useCampaigns;
