import React, { useState, useEffect } from "react";
import gk1 from "../../images/gk1.jpeg";
import gk2 from "../../images/gk2.jpeg";
import gk3 from "../../images/gk3.jpeg";
import gk4 from "../../images/gk4.jpeg";
import gk5 from "../../images/gk5.jpeg";
import gk6 from "../../images/gk6.jpeg";
import neb1 from "../../images/neb1.jpeg";
import neb2 from "../../images/neb2.jpeg";
import neb3 from "../../images/neb3.jpeg";
import neb4 from "../../images/neb4.jpeg";
import sn1 from "../../images/sn1.jpeg";
import sn2 from "../../images/sn2.jpeg";
import sn3 from "../../images/sn3.jpeg";
import sn4 from "../../images/sn4.jpeg";
import sn5 from "../../images/sn5.jpeg";
import sn6 from "../../images/sn6.jpeg";
import sn7 from "../../images/sn7.jpeg";
import pal1 from "../../images/pal1.jpeg";
import pal2 from "../../images/pal2.jpeg";
import pal3 from "../../images/pal3.jpeg";
import pal4 from "../../images/pal4.jpeg";
import pal5 from "../../images/pal5.jpeg";
import pal6 from "../../images/pal6.jpeg";
import pal7 from "../../images/pal7.jpeg";
import pal8 from "../../images/pal8.jpeg";
import pal9 from "../../images/pal9.jpeg";
import pal10 from "../../images/pal10.jpeg";
import suw1 from "../../images/suw1.jpeg";
import suw2 from "../../images/suw2.jpeg";
import suw3 from "../../images/suw3.jpeg";
import suw4 from "../../images/suw4.jpeg";
import suw5 from "../../images/suw5.jpeg";
import Scroll from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faInfo, faXmark } from "@fortawesome/free-solid-svg-icons";
import Popup from "reactjs-popup";
import { Slide } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";

const useNews = () => {
    const { t } = useTranslation();
    const AutoplaySlider = withAutoplay(AwesomeSlider);
    const [offset, setOffset] = useState(-110);

    useEffect(() => {
        const mediaQueries = [
            { query: "(min-width: 1366px)", offset: -110 },
            { query: "(min-width: 933px) and (min-height: 431px)", offset: -110 },
            { query: "(max-width: 932px) and (max-height: 430px)", offset: -80 },
        ];

        const handleMediaQueryChange = () => {
            const matchingMediaQuery = mediaQueries.find((mq) => window.matchMedia(mq.query).matches);

            if (matchingMediaQuery) {
                setOffset(matchingMediaQuery.offset);
            } else {
                setOffset(-110);
            }
        };

        mediaQueries.forEach((mq) => {
            const mediaQuery = window.matchMedia(mq.query);
            mediaQuery.addEventListener("change", handleMediaQueryChange);
        });

        handleMediaQueryChange();

        return () => {
            mediaQueries.forEach((mq) => {
                const mediaQuery = window.matchMedia(mq.query);
                mediaQuery.removeEventListener("change", handleMediaQueryChange);
            });
        };
    }, []);

    const [news] = useState([
        {
            key: "news-0",
            content: (
                <div className="slide-wrapper">
                    <Slide direction={"up"} triggerOnce={true} fraction={0.33}>
                        <div className="campaign">
                            <div className="cmp-desc-container">
                                <p id="description">{t("news-name-0")}</p>
                            </div>
                            <AutoplaySlider
                                className="autoplay-slider-container aws-btn"
                                play={true}
                                cancelOnInteraction={false}
                                interval={3000}
                                infinite={false}
                            >
                                <div className="cmp-img-container">
                                    <img src={gk1} alt="1" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={gk2} alt="2" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={gk3} alt="3" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={gk4} alt="4" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={gk5} alt="5" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={gk6} alt="6" />
                                </div>
                            </AutoplaySlider>

                            <div className="cta-buttons">
                                <Popup
                                    trigger={
                                        <div className="btn1-container">
                                            <p id="btn1">
                                                <FontAwesomeIcon className="icon" icon={faInfo} />
                                                {t("news-more-info-btn")}
                                            </p>
                                        </div>
                                    }
                                    modal
                                    lockScroll={true}
                                >
                                    {(close) => (
                                        <div className="modal">
                                            <FontAwesomeIcon className="icon" onClick={close} icon={faXmark} />
                                            <div className="title">
                                                <p>{t("news-name-0")}</p>
                                            </div>
                                            <div className="campaign-info">
                                                <p>{t("news-desc-00")}</p>
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                                <Scroll.Link to="donateId" spy={true} duration={250} smooth={false} offset={offset}>
                                    <div className="btn2-container">
                                        <p id="btn2">
                                            <FontAwesomeIcon className="icon" icon={faHeart} />
                                            {t("news-donate-btn")}
                                        </p>
                                    </div>
                                </Scroll.Link>
                            </div>
                        </div>
                    </Slide>
                </div>
            ),
        },
        {
            key: "news-1",
            content: (
                <div className="slide-wrapper">
                    <Slide direction={"up"} triggerOnce={true} fraction={0.33}>
                        <div className="campaign">
                            <div className="cmp-desc-container">
                                <p id="description">{t("news-name-1")}</p>
                            </div>
                            <AutoplaySlider
                                className="autoplay-slider-container aws-btn"
                                play={true}
                                cancelOnInteraction={false}
                                interval={3000}
                                infinite={false}
                            >
                                <div className="cmp-img-container">
                                    <img src={neb1} alt="1" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={neb2} alt="2" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={neb3} alt="3" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={neb4} alt="4" />
                                </div>
                            </AutoplaySlider>

                            <div className="cta-buttons">
                                <Popup
                                    trigger={
                                        <div className="btn1-container">
                                            <p id="btn1">
                                                <FontAwesomeIcon className="icon" icon={faInfo} />
                                                {t("news-more-info-btn")}
                                            </p>
                                        </div>
                                    }
                                    modal
                                    lockScroll={true}
                                >
                                    {(close) => (
                                        <div className="modal">
                                            <FontAwesomeIcon className="icon" onClick={close} icon={faXmark} />
                                            <div className="title">
                                                <p>{t("news-name-1")}</p>
                                            </div>
                                            <div className="campaign-info">
                                                <p>{t("news-desc-10")}</p>
                                                <p>{t("news-desc-11")}</p>
                                                <p>{t("news-desc-12")}</p>
                                                <p>{t("news-desc-13")}</p>
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                                <Scroll.Link to="donateId" spy={true} duration={250} smooth={false} offset={offset}>
                                    <div className="btn2-container">
                                        <p id="btn2">
                                            <FontAwesomeIcon className="icon" icon={faHeart} />
                                            {t("news-donate-btn")}
                                        </p>
                                    </div>
                                </Scroll.Link>
                            </div>
                        </div>
                    </Slide>
                </div>
            ),
        },
        {
            key: "news-2",
            content: (
                <div className="slide-wrapper">
                    <Slide direction={"up"} triggerOnce={true} fraction={0.33}>
                        <div className="campaign">
                            <div className="cmp-desc-container">
                                <p id="description">{t("news-name-2")}</p>
                            </div>
                            <AutoplaySlider
                                className="autoplay-slider-container aws-btn"
                                play={true}
                                cancelOnInteraction={false}
                                interval={3000}
                                infinite={false}
                            >
                                <div className="cmp-img-container">
                                    <img src={sn1} alt="1" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={sn2} alt="2" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={sn3} alt="3" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={sn4} alt="4" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={sn5} alt="5" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={sn6} alt="6" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={sn7} alt="7" />
                                </div>
                            </AutoplaySlider>

                            <div className="cta-buttons">
                                <Popup
                                    trigger={
                                        <div className="btn1-container">
                                            <p id="btn1">
                                                <FontAwesomeIcon className="icon" icon={faInfo} />
                                                {t("news-more-info-btn")}
                                            </p>
                                        </div>
                                    }
                                    modal
                                    lockScroll={true}
                                >
                                    {(close) => (
                                        <div className="modal">
                                            <FontAwesomeIcon className="icon" onClick={close} icon={faXmark} />
                                            <div className="title">
                                                <p>{t("news-name-2")}</p>
                                            </div>
                                            <div className="campaign-info">
                                                <p>{t("news-desc-20")}</p>
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                                <Scroll.Link to="donateId" spy={true} duration={250} smooth={false} offset={offset}>
                                    <div className="btn2-container">
                                        <p id="btn2">
                                            <FontAwesomeIcon className="icon" icon={faHeart} />
                                            {t("news-donate-btn")}
                                        </p>
                                    </div>
                                </Scroll.Link>
                            </div>
                        </div>
                    </Slide>
                </div>
            ),
        },
        {
            key: "news-3",
            content: (
                <div className="slide-wrapper">
                    <Slide direction={"up"} triggerOnce={true} fraction={0.33}>
                        <div className="campaign">
                            <div className="cmp-desc-container">
                                <p id="description">{t("news-name-3")}</p>
                            </div>
                            <AutoplaySlider
                                className="autoplay-slider-container aws-btn"
                                play={true}
                                cancelOnInteraction={false}
                                interval={3000}
                                infinite={false}
                            >
                                <div className="cmp-img-container">
                                    <img src={pal1} alt="1" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={pal2} alt="2" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={pal3} alt="3" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={pal4} alt="4" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={pal5} alt="5" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={pal6} alt="6" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={pal7} alt="7" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={pal8} alt="8" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={pal9} alt="9" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={pal10} alt="10" />
                                </div>
                            </AutoplaySlider>

                            <div className="cta-buttons">
                                <Popup
                                    trigger={
                                        <div className="btn1-container">
                                            <p id="btn1">
                                                <FontAwesomeIcon className="icon" icon={faInfo} />
                                                {t("news-more-info-btn")}
                                            </p>
                                        </div>
                                    }
                                    modal
                                    lockScroll={true}
                                >
                                    {(close) => (
                                        <div className="modal">
                                            <FontAwesomeIcon className="icon" onClick={close} icon={faXmark} />
                                            <div className="title">
                                                <p>{t("news-name-3")}</p>
                                            </div>
                                            <div className="campaign-info">
                                                <p>{t("news-desc-30")}</p>
                                                <p>{t("news-desc-31")}</p>
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                                <Scroll.Link to="donateId" spy={true} duration={250} smooth={false} offset={offset}>
                                    <div className="btn2-container">
                                        <p id="btn2">
                                            <FontAwesomeIcon className="icon" icon={faHeart} />
                                            {t("news-donate-btn")}
                                        </p>
                                    </div>
                                </Scroll.Link>
                            </div>
                        </div>
                    </Slide>
                </div>
            ),
        },
        {
            key: "news-4",
            content: (
                <div className="slide-wrapper">
                    <Slide direction={"up"} triggerOnce={true} fraction={0.33}>
                        <div className="campaign">
                            <div className="cmp-desc-container">
                                <p id="description">{t("news-name-4")}</p>
                            </div>
                            <AutoplaySlider
                                className="autoplay-slider-container aws-btn"
                                play={true}
                                cancelOnInteraction={false}
                                interval={3000}
                                infinite={false}
                            >
                                <div className="cmp-img-container">
                                    <img src={suw1} alt="1" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={suw2} alt="2" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={suw3} alt="3" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={suw4} alt="4" />
                                </div>
                                <div className="cmp-img-container">
                                    <img src={suw5} alt="5" />
                                </div>
                            </AutoplaySlider>

                            <div className="cta-buttons">
                                <Popup
                                    trigger={
                                        <div className="btn1-container">
                                            <p id="btn1">
                                                <FontAwesomeIcon className="icon" icon={faInfo} />
                                                {t("news-more-info-btn")}
                                            </p>
                                        </div>
                                    }
                                    modal
                                    lockScroll={true}
                                >
                                    {(close) => (
                                        <div className="modal">
                                            <FontAwesomeIcon className="icon" onClick={close} icon={faXmark} />
                                            <div className="title">
                                                <p>{t("news-name-4")}</p>
                                            </div>
                                            <div className="campaign-info">
                                                <p>{t("news-desc-40")}</p>
                                                <p>{t("news-desc-41")}</p>
                                                <p>{t("news-desc-42")}</p>
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                                <Scroll.Link to="donateId" spy={true} duration={250} smooth={false} offset={offset}>
                                    <div className="btn2-container">
                                        <p id="btn2">
                                            <FontAwesomeIcon className="icon" icon={faHeart} />
                                            {t("news-donate-btn")}
                                        </p>
                                    </div>
                                </Scroll.Link>
                            </div>
                        </div>
                    </Slide>
                </div>
            ),
        },
    ]);

    return news;
};

export default useNews;
