import React from "react";
import ss1 from "../../images/ss1.jpeg";
import ss2 from "../../images/ss2.jpeg";
import ss3 from "../../images/ss3.jpeg";
import ss4 from "../../images/ss4.jpeg";
import ss5 from "../../images/ss5.jpeg";
import ss6 from "../../images/ss6.jpeg";
import ss7 from "../../images/ss7.jpeg";
import ss8 from "../../images/ss8.jpeg";
import ss9 from "../../images/ss9.jpeg";
import ss10 from "../../images/ss10.jpeg";
import ss11 from "../../images/ss11.jpeg";
import ss12 from "../../images/ss12.jpeg";
import ss13 from "../../images/ss13.jpeg";
import ss14 from "../../images/ss14.jpeg";
import ss15 from "../../images/ss15.jpeg";
import ss16 from "../../images/ss16.jpeg";
import ss17 from "../../images/ss17.jpeg";
import ss18 from "../../images/ss18.jpeg";
import ss19 from "../../images/ss19.jpeg";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import "./Header.css";
import { useTranslation } from "react-i18next";

const Header = () => {
    const { t } = useTranslation();
    const AutoplaySlider = withAutoplay(AwesomeSlider);
    return (
        <header>
            <div className="title-container">
                <p>{t("hd-text-1")}</p>
                <p></p>
            </div>

            <div className="image-slider">
                <AutoplaySlider
                    className="autoplay-slider-container aws-btn"
                    play={true}
                    cancelOnInteraction={false}
                    interval={3000}
                >
                    <div className="image-container">
                        <img src={ss1} alt="1" />
                    </div>
                    <div className="image-container">
                        <img src={ss2} alt="2" />
                    </div>
                    <div className="image-container">
                        <img src={ss3} alt="3" />
                    </div>
                    <div className="image-container">
                        <img src={ss4} alt="4" />
                    </div>
                    <div className="image-container">
                        <img src={ss5} alt="5" />
                    </div>
                    <div className="image-container">
                        <img src={ss6} alt="6" />
                    </div>
                    <div className="image-container">
                        <img src={ss7} alt="7" />
                    </div>
                    <div className="image-container">
                        <img src={ss8} alt="8" />
                    </div>
                    <div className="image-container">
                        <img src={ss9} alt="9" />
                    </div>
                    <div className="image-container">
                        <img src={ss10} alt="10" />
                    </div>
                    <div className="image-container">
                        <img src={ss11} alt="11" />
                    </div>
                    <div className="image-container">
                        <img src={ss12} alt="12" />
                    </div>
                    <div className="image-container">
                        <img src={ss13} alt="13" />
                    </div>
                    <div className="image-container">
                        <img src={ss14} alt="14" />
                    </div>
                    <div className="image-container">
                        <img src={ss15} alt="15" />
                    </div>
                    <div className="image-container">
                        <img src={ss16} alt="16" />
                    </div>
                    <div className="image-container">
                        <img src={ss17} alt="17" />
                    </div>
                    <div className="image-container">
                        <img src={ss18} alt="18" />
                    </div>
                    <div className="image-container">
                        <img src={ss19} alt="19" />
                    </div>
                </AutoplaySlider>
            </div>
        </header>
    );
};

export default Header;
