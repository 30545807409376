import React, { useState, useContext, useEffect } from "react";
import LocaleContext from "../../LocaleContext";
import "./Navbar.css";
import Scroll from "react-scroll";
import logo from "../../h4k_logo.jpg";
import ch from "../../images/ch.png";
import en from "../../images/en.png";
import fr from "../../images/fr.png";
// import bih from "../../images/bih.png";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";

const Navbar = () => {
    const [offset, setOffset] = useState(-700);
    const { t } = useTranslation();
    const { locale } = useContext(LocaleContext);
    const [isClosed, setIsClosed] = useState(false);
    const [hamburgerClicked, setHamburgerClicked] = useState(false);
    const [flag, setFlag] = useState(true);
    const [, setIsWeb] = useState(false);
    const offsetCampaigns = 50;

    useEffect(() => {
        const mediaQueries = [
            { query: "(min-width: 1366px)", offset: -110 },
            { query: "(min-width: 933px) and (min-height: 431px)", offset: -700 },
            { query: "(max-width: 932px) and (max-height: 430px)", offset: -440 },
        ];

        const handleMediaQueryChange = () => {
            const matchingMediaQuery = mediaQueries.find((mq) => window.matchMedia(mq.query).matches);

            if (matchingMediaQuery) {
                setOffset(matchingMediaQuery.offset);
                if (matchingMediaQuery.offset === -110) {
                    setIsWeb(true);
                } else {
                    setIsWeb(false);
                }
            } else {
                setOffset(-700);
            }
        };

        mediaQueries.forEach((mq) => {
            const mediaQuery = window.matchMedia(mq.query);
            mediaQuery.addEventListener("change", handleMediaQueryChange);
        });

        handleMediaQueryChange();

        return () => {
            mediaQueries.forEach((mq) => {
                const mediaQuery = window.matchMedia(mq.query);
                mediaQuery.removeEventListener("change", handleMediaQueryChange);
            });
        };
    }, []);

    function changeLocale(l) {
        if (locale !== l) {
            i18n.changeLanguage(l);
        }
    }

    const handleHamburger = () => {
        if (flag) {
            setHamburgerClicked(true);
        } else {
            setHamburgerClicked(false);
        }
        setFlag(!flag);
    };

    const handleMenu = () => {
        setIsClosed(false);
        setHamburgerClicked(false);
        setFlag(true);
    };

    return (
        <nav>
            <div className="nav-container">
                <input
                    onChange={handleHamburger}
                    checked={hamburgerClicked}
                    className="checkbox"
                    type="checkbox"
                    name=""
                    id=""
                />
                <div className="hamburger-lines">
                    <span className="line line1"></span>
                    <span className="line line2"></span>
                    <span className="line line3"></span>
                </div>

                <div className="nav-logo">
                    <Scroll.Link
                        to="headerId"
                        spy={true}
                        duration={250}
                        smooth={false}
                        isDynamic={true}
                        offset={offset}
                    >
                        <img src={logo} alt="logo" />
                    </Scroll.Link>
                </div>

                <div className="menu-items-web">
                    <ul>
                        <li>
                            <Scroll.Link
                                to="aboutId"
                                spy={true}
                                duration={250}
                                smooth={false}
                                isDynamic={true}
                                offset={offset}
                            >
                                <div>
                                    <p>{t("nav-about-text")}</p>
                                </div>
                            </Scroll.Link>
                        </li>
                        <li>
                            <Scroll.Link
                                to="newsId"
                                spy={true}
                                duration={250}
                                smooth={false}
                                isDynamic={true}
                                offset={offset + offsetCampaigns}
                            >
                                <div>
                                    <p>{t("nav-news-text")}</p>
                                </div>
                            </Scroll.Link>
                        </li>
                        <li>
                            <Scroll.Link
                                to="campaignsId"
                                spy={true}
                                duration={250}
                                smooth={false}
                                isDynamic={true}
                                offset={offset + offsetCampaigns}
                            >
                                <div>
                                    <p>{t("nav-campaigns-text")}</p>
                                </div>
                            </Scroll.Link>
                        </li>
                        <li>
                            <Scroll.Link
                                to="projectsId"
                                spy={true}
                                duration={250}
                                smooth={false}
                                isDynamic={true}
                                offset={offset}
                            >
                                <div>
                                    <p>{t("nav-projects-text")}</p>
                                </div>
                            </Scroll.Link>
                        </li>
                        <li>
                            <Scroll.Link
                                to="contactId"
                                spy={true}
                                duration={250}
                                smooth={false}
                                isDynamic={true}
                                offset={offset}
                            >
                                <div>
                                    <p>{t("nav-contact-text")}</p>
                                </div>
                            </Scroll.Link>
                        </li>
                        <li>
                            <div className="lang-container">
                                <div id="ch" onClick={() => changeLocale("ch")}>
                                    <img src={ch} alt="CH" />
                                </div>
                                <div id="en" onClick={() => changeLocale("en")}>
                                    <img src={en} alt="EN" />
                                </div>
                                <div id="fr" onClick={() => changeLocale("fr")}>
                                    <img src={fr} alt="FR" />
                                </div>
                                {/* <div id="bih" onClick={() => changeLocale("bih")}>
                                    <img src={bih} alt="BiH" />
                                </div> */}
                            </div>
                        </li>
                    </ul>
                </div>

                <div className="menu-items" style={{ display: !isClosed && hamburgerClicked ? "flex" : "none" }}>
                    <ul>
                        <li>
                            <Scroll.Link
                                to="aboutId"
                                spy={true}
                                duration={250}
                                smooth={false}
                                isDynamic={true}
                                offset={offset - offsetCampaigns}
                            >
                                <div onClick={handleMenu}>{t("nav-about-text")}</div>
                            </Scroll.Link>
                        </li>
                        <li>
                            <Scroll.Link
                                to="newsId"
                                spy={true}
                                duration={250}
                                smooth={false}
                                isDynamic={true}
                                offset={offset}
                            >
                                <div onClick={handleMenu}>{t("nav-news-text")}</div>
                            </Scroll.Link>
                        </li>
                        <li>
                            <Scroll.Link
                                to="campaignsId"
                                spy={true}
                                duration={250}
                                smooth={false}
                                isDynamic={true}
                                offset={offset}
                            >
                                <div onClick={handleMenu}>{t("nav-campaigns-text")}</div>
                            </Scroll.Link>
                        </li>
                        <li>
                            <Scroll.Link
                                to="projectsId"
                                spy={true}
                                duration={250}
                                smooth={false}
                                isDynamic={true}
                                offset={offset - offsetCampaigns}
                            >
                                <div onClick={handleMenu}>{t("nav-projects-text")}</div>
                            </Scroll.Link>
                        </li>
                        <li>
                            <Scroll.Link
                                to="contactId"
                                spy={true}
                                duration={250}
                                smooth={false}
                                isDynamic={true}
                                offset={offset - offsetCampaigns}
                            >
                                <div onClick={handleMenu}>{t("nav-contact-text")}</div>
                            </Scroll.Link>
                        </li>
                        <li>
                            <div className="lang-container">
                                <div id="ch" onClick={() => changeLocale("ch")}>
                                    <img src={ch} alt="CH" />
                                </div>
                                <div id="en" onClick={() => changeLocale("en")}>
                                    <img src={en} alt="EN" />
                                </div>
                                <div id="fr" onClick={() => changeLocale("fr")}>
                                    <img src={fr} alt="FR" />
                                </div>
                                {/* <div id="bih" onClick={() => changeLocale("bih")}>
                                    <img src={bih} alt="BiH" />
                                </div> */}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="dummy-div"></div>
        </nav>
    );
};

export default Navbar;
