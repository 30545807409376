import React from "react";
import { Spinner } from "@material-tailwind/react";

const Loading = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                width: "100vw",
            }}
        >
            <Spinner className="h-35 w-35" />
        </div>
    );
};

export default Loading;
