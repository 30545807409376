import React, { useEffect, useContext } from "react";
import LocaleContext from "../../LocaleContext";
import "./Donate.css";
import { useTranslation } from "react-i18next";

const Donate = () => {
    const { t } = useTranslation();
    const { locale } = useContext(LocaleContext);
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://tamaro.raisenow.com/hopef-c3da/latest/widget.js";
        script.defer = true;
        document.body.appendChild(script);

        const locale_modified = locale === "ch" ? "de" : locale === "bih" ? "bs" : locale;

        function setupCustomFieldValidation(event) {
            var widget = (window["widget"] = event.data.api);
            var paymentValidations = window.rnw.tamaro.toJS(widget.computedConfig.paymentValidations);

            // adding rules for a custom field
            paymentValidations["stored_customer_donation_purpose"] = {
                required: true,
            };

            widget.config.paymentValidations = paymentValidations;
        }

        script.onload = () => {
            window.rnw.tamaro.runWidget(
                ".rnw-widget-container",
                {
                    language: locale_modified,

                    paymentFormPrefill: {
                        stored_customer_donation_purpose: null,
                    },

                    slots: {
                        profile_form_before_message: [
                            {
                                component: "field",
                                type: "text",
                                name: "stored_customer_donation_purpose",
                                label: "Spendenzweck",
                                placeholder: "",
                            },
                        ],
                    },

                    translations: {
                        en: {
                            Spendenzweck: "Donation purpose",
                        },
                        de: {
                            Spendenzweck: "Spendenzweck",
                        },
                        fr: {
                            Spendenzweck: "But du don",
                        },
                    },
                },

                window.rnw.tamaro.events["afterCreate"].subscribe(setupCustomFieldValidation)
            );
        };

        return () => {
            script.onload = null;
            document.body.removeChild(script);
        };
    }, [locale]);

    return (
        <div className="donate-container">
            <div className="title-container">
                <p>{t("dnt-title")}</p>
            </div>
            <div className="rnw-widget-container"></div>
        </div>
    );
};

export default Donate;
