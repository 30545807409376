import React, { useState, useEffect } from "react";
import "./Projects.css";
import useProjects from "./useProjects";
import { useTranslation } from "react-i18next";

const Projects = ({ useless }) => {
    const { t } = useTranslation();
    const [numberOfElementsToDisplay, setNumberOfElementsToDisplay] = useState(getInitialAmount());
    const projects = useProjects();

    const handleShowMoreClick = () => {
        setNumberOfElementsToDisplay(projects.length);
    };

    function getInitialAmount() {
        if (window.innerWidth <= 932) {
            return 3;
        } else if (window.innerWidth >= 1366) {
            return 3;
        } else {
            return 4;
        }
    }

    useEffect(() => {
        function handleResize() {
            setNumberOfElementsToDisplay(getInitialAmount());
        }

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className="projects-container">
            <div className="title-container">
                <p>{t("proj-title")}</p>
            </div>

            <div className="projects-content">
                <ul className="projects-ul">
                    {projects.slice(0, numberOfElementsToDisplay).map((project) => (
                        <li key={project.key}>{project.content}</li>
                    ))}
                </ul>
            </div>

            <div className="show-more-btn" onClick={handleShowMoreClick}>
                <p>{t("proj-show-more-btn")}</p>
            </div>
        </div>
    );
};

export default Projects;
