import React from "react";
import "./About.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faUsers, faBook, faChildReaching } from "@fortawesome/free-solid-svg-icons";
import solidarity from "../../images/solidarity.png";
import humanity from "../../images/humanity.png";
import equality from "../../images/equality.png";
import transparency from "../../images/transparency.png";
import engagement from "../../images/engagement.png";
import president from "../../images/president.jpg";
import revision from "../../images/revision.jpg";
import cashier from "../../images/cashier.jpg";
import revision2 from "../../images/revision2.jpg";
import vicep from "../../images/vicep.jpg";
import r21_22 from "../../images/report_21_22.jpeg";
import r21_22_pdf from "../../reports/report_2021_2022.pdf";
import { useTranslation } from "react-i18next";

const About = () => {
    const { t } = useTranslation();
    return (
        <div className="about-container">
            <div className="title-container">
                <p>{t("abt-title")}</p>
            </div>

            <div className="who-container">
                <p id="a">
                    <FontAwesomeIcon className="icon" icon={faChildReaching} />
                    {t("abt-who-title")}
                </p>
                <p id="b">{t("abt-who-text1")}</p>
                <p id="b">{t("abt-who-text2")}</p>
                <p id="b">{t("abt-who-text3")}</p>
            </div>

            <div className="wAv-container">
                <p id="a">
                    <FontAwesomeIcon className="icon" icon={faHeart} />
                    {t("abt-values-title")}
                </p>
                <p id="c">
                    <img src={solidarity} alt="" /> {t("abt-values-subtitle1")}
                </p>
                <p id="b">{t("abt-values-text1")}</p>
                <p id="c">
                    <img src={humanity} alt="" /> {t("abt-values-subtitle2")}
                </p>
                <p id="b">{t("abt-values-text2")}</p>
                <p id="c">
                    <img src={equality} alt="" /> {t("abt-values-subtitle3")}
                </p>
                <p id="b">{t("abt-values-text3")}</p>
                <p id="c">
                    <img src={transparency} alt="" /> {t("abt-values-subtitle4")}
                </p>
                <p id="b">{t("abt-values-text4")}</p>
                <p id="c">
                    <img src={engagement} alt="" /> {t("abt-values-subtitle5")}
                </p>
                <p id="b">{t("abt-values-text5")}</p>
            </div>

            <div className="team-container">
                <p id="a">
                    <FontAwesomeIcon className="icon" icon={faUsers} />
                    {t("abt-team-title")}
                </p>
                <div className="cards-container">
                    <div className="card">
                        <img src={president} alt="profile" />
                        <p id="position">{t("abt-team-position-1")}</p>
                        <p id="name">Aliça Ramani</p>
                    </div>
                    <div className="card">
                        <img src={revision} alt="profile" />
                        <p id="position">{t("abt-team-position-2")}</p>
                        <p id="name">Sejat Ramani</p>
                    </div>
                    <div className="card">
                        <img src={cashier} alt="profile" />
                        <p id="position">{t("abt-team-position-3")}</p>
                        <p id="name">Sabina Latiffy</p>
                    </div>
                    <div className="card">
                        <img src={revision2} alt="profile" />
                        <p id="position">{t("abt-team-position-4")}</p>
                        <p id="name">Salko Mustafi</p>
                    </div>
                    <div className="card">
                        <img src={vicep} alt="profile" />
                        <p id="position">{t("abt-team-position-5")}</p>
                        <p id="name">Mejra Mustafi</p>
                    </div>
                </div>
            </div>

            <div className="report-container">
                <p id="a">
                    <FontAwesomeIcon className="icon" icon={faBook} />
                    {t("abt-reports-title")}
                </p>
                <div className="report-pdf-container">
                    <div className="pdf">
                        <a href={r21_22_pdf} target="_blank" rel="noopener noreferrer">
                            <img src={r21_22} alt="2021 / 2022" />
                        </a>
                        <p id="year">2021 / 2022</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
