import React, { useState, useEffect } from "react";
import "./News.css";
import useNews from "./useNews";
import { useTranslation } from "react-i18next";

const News = ({ useless }) => {
    const { t } = useTranslation();
    const [numberOfElementsToDisplay, setNumberOfElementsToDisplay] = useState(getInitialAmount());
    const news = useNews();

    function getInitialAmount() {
        if (window.innerWidth <= 932) {
            return 3;
        } else if (window.innerWidth >= 1366) {
            return 3;
        } else {
            return 4;
        }
    }

    useEffect(() => {
        function handleResize() {
            setNumberOfElementsToDisplay(getInitialAmount());
        }

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleShowMoreClick = () => {
        setNumberOfElementsToDisplay(news.length);
    };

    return (
        <div className="campaigns-container">
            <div className="title-container">
                <p>{t("news-title")}</p>
            </div>

            <div className="campaigns-content">
                <ul className="campaigns-ul">
                    {news.slice(0, numberOfElementsToDisplay).map((news) => (
                        <li key={news.key}>{news.content}</li>
                    ))}
                </ul>
            </div>

            <div className="show-more-btn" onClick={handleShowMoreClick}>
                <p>{t("news-show-more-btn")}</p>
            </div>
        </div>
    );
};

export default News;
