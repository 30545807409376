import React, { useState } from "react";
import bz1 from "../../images/bz1.jpeg";
import bz2 from "../../images/bz2.jpeg";
import bz3 from "../../images/bz3.jpeg";
import bz4 from "../../images/bz4.jpeg";
import bz5 from "../../images/bz5.jpeg";
import bz6 from "../../images/bz6.jpeg";
import bz7 from "../../images/bz7.jpeg";
import eh1 from "../../images/eh1.jpeg";
import eh2 from "../../images/eh2.jpeg";
import eh3 from "../../images/eh3.jpeg";
import eh4 from "../../images/eh4.jpeg";
import eh5 from "../../images/eh5.jpeg";
import eh6 from "../../images/eh6.jpeg";
import eh7 from "../../images/eh7.jpeg";
import eh8 from "../../images/eh8.jpeg";
import eh9 from "../../images/eh9.jpeg";
import eh10 from "../../images/eh10.jpeg";
import eh11 from "../../images/eh11.jpeg";
import eh12 from "../../images/eh12.jpeg";
import bd1 from "../../images/bd1.jpeg";
import bd2 from "../../images/bd2.jpeg";
import bd3 from "../../images/bd3.jpeg";
import bd4 from "../../images/bd4.jpeg";
import bd5 from "../../images/bd5.jpeg";
import bd6 from "../../images/bd6.jpeg";
import bd7 from "../../images/bd7.jpeg";
import bd8 from "../../images/bd8.jpeg";
import bd9 from "../../images/bd9.jpeg";
import bd10 from "../../images/bd10.jpeg";
import bb1 from "../../images/bb1.jpeg";
import bb2 from "../../images/bb2.jpeg";
import bb3 from "../../images/bb3.jpeg";
import bb4 from "../../images/bb4.jpeg";
import bb5 from "../../images/bb5.jpeg";
import bb6 from "../../images/bb6.jpeg";
import bb7 from "../../images/bb7.jpeg";
import bb8 from "../../images/bb8.jpeg";
import bb9 from "../../images/bb9.jpeg";
import bb10 from "../../images/bb10.jpeg";
import bb11 from "../../images/bb11.jpeg";
import bb12 from "../../images/bb12.jpeg";
import ps1 from "../../images/ps1.jpeg";
import ps2 from "../../images/ps2.jpeg";
import ps3 from "../../images/ps3.jpeg";
import ps4 from "../../images/ps4.jpeg";
import ps5 from "../../images/ps5.jpeg";
import ps6 from "../../images/ps6.jpeg";
import ps7 from "../../images/ps7.jpeg";
import ps8 from "../../images/ps8.jpeg";
import ps9 from "../../images/ps9.jpeg";
import ps10 from "../../images/ps10.jpeg";
import ps11 from "../../images/ps11.jpeg";
import mv1 from "../../images/mv1.jpeg";
import mv2 from "../../images/mv2.jpeg";
import mv3 from "../../images/mv3.jpeg";
import mv4 from "../../images/mv4.jpeg";
import mv5 from "../../images/mv5.jpeg";
import ff1 from "../../images/ff1.jpeg";
import ff2 from "../../images/ff2.jpeg";
import ff3 from "../../images/ff3.jpeg";
import ff4 from "../../images/ff4.jpeg";
import ff5 from "../../images/ff5.jpeg";
import ff6 from "../../images/ff6.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faXmark } from "@fortawesome/free-solid-svg-icons";
import Popup from "reactjs-popup";
import { Slide } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";

const useProjects = () => {
    const { t } = useTranslation();
    const AutoplaySlider = withAutoplay(AwesomeSlider);

    const [projects] = useState([
        {
            key: "project-0",
            content: (
                <div className="slide-wrapper">
                    <Slide direction={"up"} triggerOnce={true} fraction={0.33}>
                        <div className="project">
                            <div className="proj-desc-container">
                                <p id="description">{t("proj-name-0")}</p>
                            </div>

                            <AutoplaySlider
                                className="autoplay-slider-container aws-btn"
                                play={true}
                                cancelOnInteraction={false}
                                interval={3000}
                                infinite={false}
                            >
                                <div className="proj-img-container">
                                    <img src={bz1} alt="1" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={bz2} alt="2" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={bz3} alt="3" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={bz4} alt="4" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={bz5} alt="5" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={bz6} alt="6" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={bz7} alt="7" />
                                </div>
                            </AutoplaySlider>

                            <div className="cta-buttons">
                                <Popup
                                    className="project-popup"
                                    trigger={
                                        <div className="btn1-container">
                                            <p id="btn1">
                                                <FontAwesomeIcon className="icon" icon={faInfo} />
                                                {t("proj-more-info-btn")}
                                            </p>
                                        </div>
                                    }
                                    modal
                                    lockScroll={true}
                                >
                                    {(close) => (
                                        <div className="modal">
                                            <FontAwesomeIcon className="icon" onClick={close} icon={faXmark} />
                                            <div className="title">
                                                <p>{t("proj-name-0")}</p>
                                            </div>
                                            <div className="project-info">
                                                <p>{t("proj-desc-00")}</p>
                                                <p>{t("proj-desc-01")}</p>
                                                <p>{t("proj-desc-02")}</p>
                                                <p>{t("proj-desc-03")}</p>
                                                <p>{t("proj-desc-04")}</p>
                                                <p>{t("proj-desc-05")}</p>
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                            </div>
                        </div>
                    </Slide>
                </div>
            ),
        },
        {
            key: "project-1",
            content: (
                <div className="slide-wrapper">
                    <Slide direction={"up"} triggerOnce={true} fraction={0.33}>
                        <div className="project">
                            <div className="proj-desc-container">
                                <p id="description">{t("proj-name-1")}</p>
                            </div>

                            <AutoplaySlider
                                className="autoplay-slider-container aws-btn"
                                play={true}
                                cancelOnInteraction={false}
                                interval={3000}
                                infinite={false}
                            >
                                <div className="proj-img-container">
                                    <img src={eh1} alt="1" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={eh2} alt="2" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={eh3} alt="3" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={eh4} alt="4" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={eh5} alt="5" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={eh6} alt="6" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={eh7} alt="7" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={eh8} alt="8" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={eh9} alt="9" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={eh10} alt="10" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={eh11} alt="11" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={eh12} alt="12" />
                                </div>
                            </AutoplaySlider>

                            <div className="cta-buttons">
                                <Popup
                                    className="project-popup"
                                    trigger={
                                        <div className="btn1-container">
                                            <p id="btn1">
                                                <FontAwesomeIcon className="icon" icon={faInfo} />
                                                {t("proj-more-info-btn")}
                                            </p>
                                        </div>
                                    }
                                    modal
                                    lockScroll={true}
                                >
                                    {(close) => (
                                        <div className="modal">
                                            <FontAwesomeIcon className="icon" onClick={close} icon={faXmark} />
                                            <div className="title">
                                                <p>{t("proj-name-1")}</p>
                                            </div>
                                            <div className="project-info">
                                                <p>{t("proj-desc-10")}</p>
                                                <p>{t("proj-desc-11")}</p>
                                                <p>{t("proj-desc-12")}</p>
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                            </div>
                        </div>
                    </Slide>
                </div>
            ),
        },
        {
            key: "project-2",
            content: (
                <div className="slide-wrapper">
                    <Slide direction={"up"} triggerOnce={true} fraction={0.33}>
                        <div className="project">
                            <div className="proj-desc-container">
                                <p id="description">{t("proj-name-2")}</p>
                            </div>
                            <AutoplaySlider
                                className="autoplay-slider-container aws-btn"
                                play={true}
                                cancelOnInteraction={false}
                                interval={3000}
                                infinite={false}
                            >
                                <div className="proj-img-container">
                                    <img src={bd1} alt="1" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={bd2} alt="2" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={bd3} alt="3" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={bd4} alt="4" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={bd5} alt="5" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={bd6} alt="6" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={bd7} alt="7" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={bd8} alt="8" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={bd9} alt="9" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={bd10} alt="10" />
                                </div>
                            </AutoplaySlider>

                            <div className="cta-buttons">
                                <Popup
                                    className="project-popup"
                                    trigger={
                                        <div className="btn1-container">
                                            <p id="btn1">
                                                <FontAwesomeIcon className="icon" icon={faInfo} />
                                                {t("proj-more-info-btn")}
                                            </p>
                                        </div>
                                    }
                                    modal
                                    lockScroll={true}
                                >
                                    {(close) => (
                                        <div className="modal">
                                            <FontAwesomeIcon className="icon" onClick={close} icon={faXmark} />
                                            <div className="title">
                                                <p>{t("proj-name-2")}</p>
                                            </div>
                                            <div className="project-info">
                                                <p>{t("proj-desc-20")}</p>
                                                <p>{t("proj-desc-21")}</p>
                                                <p>{t("proj-desc-22")}</p>
                                                <p>{t("proj-desc-23")}</p>
                                                <p>{t("proj-desc-24")}</p>
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                            </div>
                        </div>
                    </Slide>
                </div>
            ),
        },
        {
            key: "project-3",
            content: (
                <div className="slide-wrapper">
                    <Slide direction={"up"} triggerOnce={true} fraction={0.33}>
                        <div className="project">
                            <div className="proj-desc-container">
                                <p id="description">{t("proj-name-3")}</p>
                            </div>
                            <AutoplaySlider
                                className="autoplay-slider-container aws-btn"
                                play={true}
                                cancelOnInteraction={false}
                                interval={3000}
                                infinite={false}
                            >
                                <div className="proj-img-container">
                                    <img src={bb1} alt="1" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={bb2} alt="2" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={bb3} alt="3" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={bb4} alt="4" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={bb5} alt="5" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={bb6} alt="6" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={bb7} alt="7" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={bb8} alt="8" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={bb9} alt="9" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={bb10} alt="10" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={bb11} alt="11" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={bb12} alt="12" />
                                </div>
                            </AutoplaySlider>

                            <div className="cta-buttons">
                                <Popup
                                    className="project-popup"
                                    trigger={
                                        <div className="btn1-container">
                                            <p id="btn1">
                                                <FontAwesomeIcon className="icon" icon={faInfo} />
                                                {t("proj-more-info-btn")}
                                            </p>
                                        </div>
                                    }
                                    modal
                                    lockScroll={true}
                                >
                                    {(close) => (
                                        <div className="modal">
                                            <FontAwesomeIcon className="icon" onClick={close} icon={faXmark} />
                                            <div className="title">
                                                <p>{t("proj-name-3")}</p>
                                            </div>
                                            <div className="project-info">
                                                <p>{t("proj-desc-30")}</p>
                                                <p>{t("proj-desc-31")}</p>
                                                <p>{t("proj-desc-32")}</p>
                                                <p>{t("proj-desc-33")}</p>
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                            </div>
                        </div>
                    </Slide>
                </div>
            ),
        },
        {
            key: "project-4",
            content: (
                <div className="slide-wrapper">
                    <Slide direction={"up"} triggerOnce={true} fraction={0.33}>
                        <div className="project">
                            <div className="proj-desc-container">
                                <p id="description">{t("proj-name-4")}</p>
                            </div>
                            <AutoplaySlider
                                className="autoplay-slider-container aws-btn"
                                play={true}
                                cancelOnInteraction={false}
                                interval={3000}
                                infinite={false}
                            >
                                <div className="proj-img-container">
                                    <img src={ps1} alt="1" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={ps2} alt="2" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={ps3} alt="3" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={ps4} alt="4" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={ps5} alt="5" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={ps6} alt="6" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={ps7} alt="7" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={ps8} alt="8" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={ps9} alt="9" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={ps10} alt="10" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={ps11} alt="11" />
                                </div>
                            </AutoplaySlider>

                            <div className="cta-buttons">
                                <Popup
                                    className="project-popup"
                                    trigger={
                                        <div className="btn1-container">
                                            <p id="btn1">
                                                <FontAwesomeIcon className="icon" icon={faInfo} />
                                                {t("proj-more-info-btn")}
                                            </p>
                                        </div>
                                    }
                                    modal
                                    lockScroll={true}
                                >
                                    {(close) => (
                                        <div className="modal">
                                            <FontAwesomeIcon className="icon" onClick={close} icon={faXmark} />
                                            <div className="title">
                                                <p>{t("proj-name-4")}</p>
                                            </div>
                                            <div className="project-info">
                                                <p>{t("proj-desc-40")}</p>
                                                <p>{t("proj-desc-41")}</p>
                                                <p>{t("proj-desc-42")}</p>
                                                <p>{t("proj-desc-43")}</p>
                                                <p>{t("proj-desc-44")}</p>
                                                <p>{t("proj-desc-45")}</p>
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                            </div>
                        </div>
                    </Slide>
                </div>
            ),
        },
        {
            key: "project-5",
            content: (
                <div className="slide-wrapper">
                    <Slide direction={"up"} triggerOnce={true} fraction={0.33}>
                        <div className="project">
                            <div className="proj-desc-container">
                                <p id="description">{t("proj-name-5")}</p>
                            </div>
                            <AutoplaySlider
                                className="autoplay-slider-container aws-btn"
                                play={true}
                                cancelOnInteraction={false}
                                interval={3000}
                                infinite={false}
                            >
                                <div className="proj-img-container">
                                    <img src={mv1} alt="1" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={mv2} alt="2" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={mv3} alt="3" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={mv4} alt="4" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={mv5} alt="5" />
                                </div>
                            </AutoplaySlider>

                            <div className="cta-buttons">
                                <Popup
                                    className="project-popup"
                                    trigger={
                                        <div className="btn1-container">
                                            <p id="btn1">
                                                <FontAwesomeIcon className="icon" icon={faInfo} />
                                                {t("proj-more-info-btn")}
                                            </p>
                                        </div>
                                    }
                                    modal
                                    lockScroll={true}
                                >
                                    {(close) => (
                                        <div className="modal">
                                            <FontAwesomeIcon className="icon" onClick={close} icon={faXmark} />
                                            <div className="title">
                                                <p>{t("proj-name-5")}</p>
                                            </div>
                                            <div className="project-info">
                                                <p>{t("proj-desc-50")}</p>
                                                <p>{t("proj-desc-51")}</p>
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                            </div>
                        </div>
                    </Slide>
                </div>
            ),
        },
        {
            key: "project-6",
            content: (
                <div className="slide-wrapper">
                    <Slide direction={"up"} triggerOnce={true} fraction={0.33}>
                        <div className="project">
                            <div className="proj-desc-container">
                                <p id="description">{t("proj-name-6")}</p>
                            </div>
                            <AutoplaySlider
                                className="autoplay-slider-container aws-btn"
                                play={true}
                                cancelOnInteraction={false}
                                interval={3000}
                                infinite={false}
                            >
                                <div className="proj-img-container">
                                    <img src={ff1} alt="1" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={ff2} alt="2" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={ff3} alt="3" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={ff4} alt="4" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={ff5} alt="5" />
                                </div>
                                <div className="proj-img-container">
                                    <img src={ff6} alt="6" />
                                </div>
                            </AutoplaySlider>

                            <div className="cta-buttons">
                                <Popup
                                    className="project-popup"
                                    trigger={
                                        <div className="btn1-container">
                                            <p id="btn1">
                                                <FontAwesomeIcon className="icon" icon={faInfo} />
                                                {t("proj-more-info-btn")}
                                            </p>
                                        </div>
                                    }
                                    modal
                                    lockScroll={true}
                                >
                                    {(close) => (
                                        <div className="modal">
                                            <FontAwesomeIcon className="icon" onClick={close} icon={faXmark} />
                                            <div className="title">
                                                <p>{t("proj-name-6")}</p>
                                            </div>
                                            <div className="project-info">
                                                <p>{t("proj-desc-60")}</p>
                                                <p>{t("proj-desc-61")}</p>
                                                <p>{t("proj-desc-62")}</p>
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                            </div>
                        </div>
                    </Slide>
                </div>
            ),
        },
    ]);
    return projects;
};

export default useProjects;
