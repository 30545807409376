import React, { useState, useEffect } from "react";
import Scroll from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faTiktok, faFacebook } from "@fortawesome/free-brands-svg-icons";
import "./DonateButton.css";
import { useTranslation } from "react-i18next";

const DonateButton = () => {
    const { t } = useTranslation();
    const [offset, setOffset] = useState(-100);

    useEffect(() => {
        const mediaQueries = [
            { query: "(min-width: 1366px)", offset: -110 },
            { query: "(min-width: 933px) and (min-height: 431px)", offset: -110 },
            { query: "(max-width: 932px) and (max-height: 430px)", offset: -80 },
        ];

        const handleMediaQueryChange = () => {
            const matchingMediaQuery = mediaQueries.find((mq) => window.matchMedia(mq.query).matches);

            if (matchingMediaQuery) {
                setOffset(matchingMediaQuery.offset);
            } else {
                setOffset(-100);
            }
        };

        mediaQueries.forEach((mq) => {
            const mediaQuery = window.matchMedia(mq.query);
            mediaQuery.addEventListener("change", handleMediaQueryChange);
        });

        handleMediaQueryChange();

        return () => {
            mediaQueries.forEach((mq) => {
                const mediaQuery = window.matchMedia(mq.query);
                mediaQuery.removeEventListener("change", handleMediaQueryChange);
            });
        };
    }, []);

    return (
        <>
            <div className="typed-text">
                <a
                    href="https://www.instagram.com/hopeforkids__?igsh=MWY0Y2s3YTBmYjBvZQ%3D%3D&utm_source=qr"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <div>
                        <FontAwesomeIcon className="icon" id="a" icon={faInstagram} />
                        <p>@hopeforkids__</p>
                    </div>
                </a>
                <a
                    href="https://www.tiktok.com/@hopeforkids__?_t=8jbgMqhc8Uz&_r=1"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <div>
                        <FontAwesomeIcon className="icon" id="b" icon={faTiktok} />
                        <p>@hopeforkids__</p>
                    </div>
                </a>
                <a
                    href="https://www.facebook.com/profile.php?id=100082016421817"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <div>
                        <FontAwesomeIcon className="icon" id="c" icon={faFacebook} />
                        <p>@hopeforkids</p>
                    </div>
                </a>
            </div>
            <Scroll.Link to="donateId" spy={true} duration={250} smooth={false} offset={offset}>
                <div className="donate-btn">
                    <p>
                        <FontAwesomeIcon className="icon" icon={faHeart} />
                        {t("db-donate-text")}
                    </p>
                </div>
            </Scroll.Link>
        </>
    );
};

export default DonateButton;
